<template>
  <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
    <v-stepper v-model="currentStep" class="mt-2">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > PaymentSteps.CHOOSE_PRODUCT" :step="PaymentSteps.CHOOSE_PRODUCT">
          {{ $t('cart.chooseProduct') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="currentStep > PaymentSteps.DELIVERY_INFO" :step="PaymentSteps.DELIVERY_INFO">
          {{ $t('cart.shipmentDetails') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="currentStep > PaymentSteps.PAYMENT" :step="PaymentSteps.PAYMENT">
          {{ $t('cart.payment') }}
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-row justify="center">
      <v-col cols="12" md="7">
        <v-row class="mt-4">
          <v-col cols="12" class="d-flex">
            <div style="width: 100%;">
              <h1 class="font-weight-light title-cart-size text-left">
                {{ $t("cart.title") }} {{orderCode ? '#' + orderCode : ''}}
              </h1>
            </div>
          </v-col>
          <v-col cols="12" style="max-height: 550px; overflow-y: auto;">
            <div v-if="cartList.length == 0">
              {{ $t("cart.noProductInCart") }}
            </div>
            <!--  -->
            <v-data-table
              :items="cartList"
              class="elevation-1"
              hide-default-footer
              :mobile-breakpoint="0"
            >
              <template
                v-slot:item="props">
                  <tr style="border: none">
                    <td 
                      rowspan="3"
                      class="p-2">
                      <img :src="props.item.image" class="img-voucher-class"/>
                    </td>
                    <td
                      rowspan="3"
                      class="font-medium pl-2">
                      {{ props.item.name }}
                    </td>
                    <td
                      colspan="3"
                      style="height: fit-content; border: none"
                      class="text-right py-1">
                      <div v-if="props.item.warehouse > 0">{{ $t('product.detail.remainItems', {count: props.item.warehouse}) }}</div>
                      <div v-else style="color: red">{{ $t('productOrder.outOfStock') }}</div>
                    </td>
                  </tr>
                  <tr style="border: none; margin-bottom: 10px">
                    <td class="text-right" style="white-space: nowrap; border: none">
                      <div class="px-4" style="display: flex;">
                        <div
                          class="cursor-pointer font-medium"
                          style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                          @click="decreaseQuantity(props.item)"
                        >
                          -
                        </div>
                        <div
                          class="font-medium"
                          style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                        >
                          {{ props.item.quantity }}
                        </div>
                        <div
                          class="cursor-pointer font-medium"
                          style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                          @click="increaseQuantity(props.item)"
                        >
                          +
                        </div>
                      </div>
                    </td>
                    <td class="font-medium" style="border: none">
                      {{ formatMoney(props.item.price) }}
                    </td>
                    <td style="border: none">
                      <v-icon
                        class="red--text cursor-pointer"
                        style="float: right;"
                        @click="onRemoveProductFromCart(props.item.name, props.item.voucherOrderCartItemId, props.item.id)"
                        >mdi-trash-can</v-icon
                      >
                    </td>
                  </tr>
                  <tr style="border: none">
                    <td
                      colspan="3"
                      style="height: 27px;"
                      class="text-right py-1">
                      <div
                        v-if="props.item.numberOfUsesVoucherValid !== null"
                        class="font-small"
                      >
                        <span
                          :style="`color: ${props.item.numberOfUsesVoucherValid === 0 ? 'red' : ''}; padding-top: 3px;`"> {{ $t('cart.productCanBuyTimes', {'0': props.item.numberOfUsesVoucherValid}) }}
                        </span>
                      </div>
                    </td>
                  </tr>
              </template>
            </v-data-table>
            <!--  -->
            <!-- <table class="table" style="width: 100%;">
              <tr v-for="(cart, index) in cartList" :key="cart + index" class="text-left">
                <td>
                  <img :src="cart.image" width="70px" height="50px">
                </td>
                <td class="font-medium pl-2">
                  {{ cart.name }}
                </td>
                <td colspan="3">
                  <table class="table m-0 cart-content-action-table">
                    <tr>
                      <td colspan="3">1</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="px-4" style="display: flex;">
                          <div
                            class="cursor-pointer font-medium"
                            style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                            @click="decreaseQuantity(cart)"
                          >
                            -
                          </div>
                          <div
                            class="font-medium"
                            style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                          >
                            {{ cart.quantity }}
                          </div>
                          <div
                            class="cursor-pointer font-medium"
                            style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                            @click="increaseQuantity(cart)"
                          >
                            +
                          </div>
                        </div>
                      </td>
                      <td>
                        {{ formatMoney(cart.price) }}
                      </td>
                      <td>
                        <v-icon
                          class="red--text cursor-pointer"
                          style="float: right;"
                          @click="onRemoveProductFromCart(cart.name, cart.voucherOrderCartItemId, cart.id)"
                          >mdi-trash-can</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">3</td>
                    </tr>
                  </table>
                </td>
                <td class="text-right" style="white-space: nowrap;">
                  <div class="px-4" style="display: flex;">
                    <div
                      class="cursor-pointer font-medium"
                      style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                      @click="decreaseQuantity(cart)"
                    >
                      -
                    </div>
                    <div
                      class="font-medium"
                      style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                    >
                      {{ cart.quantity }}
                    </div>
                    <div
                      class="cursor-pointer font-medium"
                      style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                      @click="increaseQuantity(cart)"
                    >
                      +
                    </div>
                  </div>
                  <div
                    v-if="cart.numberOfUsesVoucherValid !== null"
                    class="px-4 font-small" style="display: flex;"
                  >
                    <span
                      :style="`color: ${cart.numberOfUsesVoucherValid === 0 ? 'red' : ''}; padding-top: 3px;`"> {{ $t('cart.productCanBuyTimes', {'0': cart.numberOfUsesVoucherValid}) }}
                    </span>
                  </div>
                </td>
                <td class="font-medium">
                  <div v-if="cart.warehouse > 0">{{ $t('product.detail.remainItems', {count: cart.warehouse}) }}</div>
                  <div v-else style="color: red">{{ $t('productOrder.outOfStock') }}</div>
                  {{ formatMoney(cart.price) }}
                </td>
                <td>
                  <v-icon
                    class="red--text cursor-pointer"
                    style="float: right;"
                    @click="onRemoveProductFromCart(cart.name, cart.voucherOrderCartItemId, cart.id)"
                    >mdi-trash-can</v-icon
                  >
                </td>
              </tr>
            </table> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="d-md-flex">
        <!-- Product list -->
        <v-row class="mt-4">
          <v-col
            cols="12"
            style="background-color: #F8F8F8;"
            class="d-flex flex-column"
          >
            <!-- RECEIVER'S INFORMATION -->
            <div class="mb-2">
              <div class="d-flex justify-space-between" style="width: 100%;">
                <p class="font-weight-bold font-medium text-left">
                  {{ $t('productOrder.voucherRecipientInfo') }}
                </p>
                <p v-if="currentStep == PaymentSteps.DELIVERY_INFO" class="font-weight-light font-medium text-left cursor-pointer" style="color: #6289F9;" @click="onChangeShipmentDetailInfo">
                  {{ $t('common.change') }}
                </p>
              </div>
              <div style="width: 100%;">
                <span class="font-weight-light font-medium text-left d-block">
                  {{ $t('common.fullname') + ': ' + (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.fullname ? SHIPMENT_DETAILS_DATA.fullname : '')}}
                </span>
                <span class="font-weight-light font-medium text-left d-block">
                  {{ $t('profile.phone') + ': ' + (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.phone ? SHIPMENT_DETAILS_DATA.phone : '') }}
                </span>
                <span class="font-weight-light font-medium text-left d-block">
                  {{ $t('common.email') + ': ' + (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.email ? SHIPMENT_DETAILS_DATA.email : '') }}
                </span>
                <div class="font-small text-left pt-1">
                  <span class="red--text">{{ $t('cart.voucherInfoSentViaEmail') }}</span>
                  <span class="d-block red--text">{{ $t('cart.makeSureEmailIsEnteredCorrectly') }}</span>
                </div>
              </div>
            </div>
            <!-- Order detail -->
            <div style="width: 100%;">
              <h1 class="font-weight-light title-cart-size text-left">
                {{ $t("cart.orderSummary") }}
              </h1>
            </div>
            <div class="mt-4" style="border-bottom: 1px solid #cccccc;">
              <div class="d-block mb-2">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("cart.provisional") }}</span>
                  <span>{{ getTotalPrice() }}</span>
                </div>
              </div>
              <div class="d-block mb-2">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("cart.fee") }}</span>
                  <span>{{ tax }}%</span>
                </div>
              </div>
            </div>
            <div class="d-block mt-2">
              <div class="d-flex justify-space-between">
                <span>{{
                  $t("productOrder.tableList.header.totalPrice")
                }}</span>
                <span>{{ getTotalPriceWithFee() }}</span>
              </div>
            </div>
            <!-- Promotion code -->
            <div class="d-block mt-2">
              <div class="d-flex justify-space-between align-center">
                <span>{{ $t("productOrder.promotion.code") + ':' }}</span>
                <v-form
                  ref="entryForm"
                  @submit.prevent="applyPromotionCode"
                  v-model="valid"
                  class="d-flex align-center"
                >
                  <v-text-field
                    v-model="promotionCode"
                    :rules="promotionCodeRules"
                    :disabled="cartList.length === 0"
                    class="mr-1"
                    :label="$t('productOrder.promotion.code')"
                  ></v-text-field>
                  <v-btn type="submit" color="warning" small :disabled="cartList.length === 0">{{ $t('productOrder.promotion.apply') }}</v-btn>
                </v-form>
              </div>
            </div>
            <!-- Price reduced -->
            <div class="d-block mt-2" v-if="promotionCodeValid">
              <div
                v-for="(item, index) in promotionDataList"
                :key="item + index"
                style="border-bottom: 1px solid #cccccc;"
                >
                <div class="d-flex justify-space-between">
                  <span
                    v-if="item.applyType === promotionTypeEnum.PRICE"
                    class="text-left"
                  >
                    {{ $t("paymentMethod.discountOnBill") }}
                  </span>
                  <span v-else class="text-left">{{ $t("paymentMethod.discountOnVoucher") }}
                    <span style="font-weight: bold;">{{ item.voucherName }}:</span>
                  </span>
                  <div style="display: flex; padding-left: 5px;">
                    <!-- <span>{{ getPriceReduced(priceReduced) }}</span> -->
                    <span>{{ item.promotionType === promotionTypeEnum.PRICE ? formatMoney(item.promotionValue) : item.promotionValue + '%'}}</span>
                    <v-icon
                      class="red--text cursor-pointer ml-2"
                      style="float: right; align-self: start;"
                      @click="onRemovePromotion(item, index)"
                      >mdi-close</v-icon
                    >
                  </div>
                </div>
                <div class="d-flex">
                  {{ $t('paymentMethod.applicableCode') }}: {{ item.promotionCode }}
                </div>
              </div>
            </div>
            <!-- Price reduced -->
            <div class="d-block mt-2" v-if="promotionCodeValid">
              <div class="d-flex justify-space-between">
                <span>{{
                  $t("productOrder.promotion.totalPriceToPay") + ':'
                }}</span>
                <span>{{ formatMoney(getFinalPrice()) }}</span>
              </div>
            </div>
            <v-btn v-if="currentStep == PaymentSteps.PAYMENT" class="ma-2" color="warning" @click="onBackStep()">
              {{ $t("common.back") }}
            </v-btn>
            <v-btn v-else class="ma-2" color="primary" @click="onGoChoosePaymentMethod()">
              {{ $t('cart.payment') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="currentStep == PaymentSteps.PAYMENT" style="border-top: 1px solid #c5c5c5;">
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12">
            <div style="width: 100%;">
              <h1 class="font-weight-light title-cart-size text-left">
                {{ $t("paymentMethod.choose") }}
              </h1>
            </div>
            <div v-for="(paymentMethod, index) in paymentMethods" :key="paymentMethod + index">
              <div class="d-flex">
                <label class="d-flex cursor-pointer">
                  <input class="mr-2" type="radio" :value="paymentMethod.type" v-model="paymentMethodSelected">
                  <div class="mt-2">
                    <img v-if="paymentMethod.type==PaymentMethodType.MOMO_WALLET" src="@/assets/logo_momo.png" class="paymentLogo">
                    <img v-else-if="paymentMethod.type==PaymentMethodType.ZALOPAY_WALLET" src="@/assets/logo_zalopay.png" class="paymentLogo">
                    <span style="vertical-align: middle;">{{ $t(getPaymentMethodName(paymentMethod.type)) }}</span>
                  </div>
                </label>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-btn :loading="isLoading" class="ma-2" color="primary" :disabled="paymentMethodSelected==null" @click="onBuyVoucherDirectly()">
        {{ $t("cart.orderConfirmation") }}
      </v-btn>
    </v-row>
    <div class="vh-150px"></div>
    <payment-info-modal
      ref="confirmModal"
      @confirm="onConfirmBuyVoucherDirectly"/>
    <momo-payment-modal
      ref="paymentMomoModal" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import functionUtils from "Utils/functionUtils";
import PromotionTypeEnum from 'Enum/promotionTypeEnum'
import PaymentMethodType from "Enum/paymentMethodType"
import VoucherProductApplyType from "Enum/voucherProductApplyType"
import StoreChannelType from 'Enum/storeChannelType'
import PaymentInfoModal from './PaymentInfoModal.vue';
import MomoPaymentModal from '../Payments/MomoPaymentModal.vue';
const PaymentSteps = {
    CHOOSE_PRODUCT: 1,
    DELIVERY_INFO: 2,
    PAYMENT: 3,
}

export default {
  components: {
    PaymentInfoModal,
    MomoPaymentModal
  },
  data() {
    return {
      cartList: [],
      promotionCodeRules: [],
      promotionCode: null,
      valid: false,
      promotionCodeValid: false,
      priceReduced: 0,
      promotionType: null,
      promotionId: null,
      promotionCodeOrigin: null,
      isLoading: false,
      tax: 0,
      PaymentMethodType: PaymentMethodType,
      paymentMethods: [],
      paymentMethodSelected: null,
      PaymentSteps: PaymentSteps,
      currentStep: PaymentSteps.DELIVERY_INFO,
      orderCode: null,
      baoKimPaymentMethodSelect: null,
      promotionDataList: [],
      promotionTypeEnum: PromotionTypeEnum,
      totalPromotionPrice: 0,
      headers: [
        { text: "#", value: "image" },
        { text: "", value: "name" },
        {
          text: "",
          value: "quantity",
        },
        { text: "", value: "product" },
        {
          text: "",
          value: "totalPrice",
        },
        { text: "", value: "status" },
      ]
    };
  },
  computed: {
    ...mapGetters(["GET_VOUCHER_ORDER_CART_LIST_DATA", "SHIPMENT_DETAILS_DATA"]),
  },
  watch: {
    promotionCode() {
      this.promotionCodeRules = [];
    },
    GET_VOUCHER_ORDER_CART_LIST_DATA () {
      let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
      this.cartList = []
      data.forEach(e => {
        let item = {
          id: e.voucher_info.workpiece_id,
          image: e.voucher_info.image,
          quantity: e.number_of_uses_voucher_valid === 0 ? 0 : e.quantity,
          price: !functionUtils.validateStringIsNull(e.extra_info.product.price_promo) ? e.extra_info.product.price_promo : e.voucher_info.price,
          name: e.voucher_info.name,
          code: e.voucher_info.serial,
          voucherOrderCartId: e.voucher_order_cart_id,
          voucherOrderCartItemId: e.id,
          warehouse: e.warehouse,
          isProductPromotion: false,
          promotionIdApply: null,
          issuanceId: e.issuance_id,
          numberOfUsesVoucherValid: e.number_of_uses_voucher_valid
        }
        this.cartList.push(item)
      })
      //
      if (this.cartList.length != 0 && this.orderCode === null) {
        this.GET_CREATE_ORDER_CODE_PREPARE().then(
          function (res) {
            let data = res.data
            this.orderCode = data.code
          }.bind(this)
        )
      }
      // 
      this.getPromotionSession()
    }
  },
  created() {
    this.GET_PAYMENT_METHODS_LIST().then(
      function (res) {
        let data = res.data
        for (let i = 0, size = data.length; i < size; i++) {
          let obj = {
            type: data[i].payment_method,
            description: data[i].description,
          }
          this.paymentMethods.push(obj)
        }
      }.bind(this)
    )
    this.getVoucherOrderCart()
    this.getBaoKimPaymentList()
  },
  methods: {
    /**
     * Back step
     */
    onBackStep: function () {
      if (this.currentStep === PaymentSteps.PAYMENT) {
        this.currentStep = this.currentStep - 1
      }
    },
    /**
    * Go choose payment method
    */
    onGoChoosePaymentMethod: function () {
      let validateQuantityProduct = this.validateQuantityProduct()
      if (validateQuantityProduct) {
        this.currentStep = PaymentSteps.PAYMENT
      }
    },
    // validate quantity
    validateQuantityProduct: function () {
      let index = this.cartList.findIndex(x => !functionUtils.isNull(x.numberOfUsesVoucherValid) && x.quantity > x.numberOfUsesVoucherValid)
      if (index !== -1) {
        this.$toast.open({
          message: this.$t('cart.validProductOnCart', { '0': this.cartList[index].name, '1': this.cartList[index].numberOfUsesVoucherValid }),
          type: "error",
          ...this.$toastConfig,
        });
        return false
      }
      index = this.cartList.findIndex(x => x.warehouse < 1)
      if (index !== -1) {
        this.$toast.open({
          message: this.$t('cart.productOutOfStock', { '0': this.cartList[index].name }),
          type: "error",
          ...this.$toastConfig,
        });
        return false
      }
      return true
    },
    /**
     * Change shipment detail info
     */
    onChangeShipmentDetailInfo: function () {
      this.$router.push({
        name: 'ShipmentDetails'
      })
    },
    /**
     * Get price reduced
     */
    getPriceReduced: function () {
      if (this.promotionType == PromotionTypeEnum.PRICE) {
        return this.formatMoney(this.priceReduced)
      } else if (this.promotionType == PromotionTypeEnum.PERCENT) {
        return this.priceReduced + '%'
      }
    },
    /**
     * Get final price
     */
    getFinalPrice: function () {
      let price = 0;
      let promotionProductPrice = 0
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        let productPrice = this.cartList[i].price * this.cartList[i].quantity;
        price += productPrice
        promotionProductPrice += this.getPricePromotionVoucher(this.cartList[i].id, this.cartList[i].price)
      }
      // total price - promotion price
      price = price - promotionProductPrice
      // minus promotion on bill
      let promotionTotalObj = this.getPromotionOnBill()
      price = (price + (price * this.tax) / 100)
      if (this.promotionCodeValid) {
        if (promotionTotalObj.price) {
          price = price - promotionTotalObj.price
        } 
        if (promotionTotalObj.percent > 0) {
          price = price - (price * promotionTotalObj.percent / 100)
        }
      }
      this.totalPromotionPrice = promotionProductPrice + promotionTotalObj
      return price > 0 ? price : 0
    },
    getPricePromotionVoucher: function (productId, productTotalPrice) {
      let result = 0
      let promotionData = this.promotionDataList.filter(x => x.applyType === VoucherProductApplyType.VOUCHER && x.voucherPromotionId === productId)
      if (promotionData.length > 0) {
        if (promotionData[0].promotionType === PromotionTypeEnum.PRICE) {
          result = promotionData[0].promotionValue
        }
        if (promotionData[0].promotionType === PromotionTypeEnum.PERCENT) {
          result = productTotalPrice * promotionData[0].promotionValue / 100
        }
      }
      return result
    },
    // get promotion on bill
    getPromotionOnBill: function () {
      let pricePromotionOnBill = 0
      let percentPromotionOnBill = 0
      let promotionOnBillList = this.promotionDataList.filter(x => x.applyType === VoucherProductApplyType.ORDER)
      promotionOnBillList.forEach(e => {
        if (e.promotionType === PromotionTypeEnum.PRICE) {
          pricePromotionOnBill += e.promotionValue
        }
        if (e.promotionType === PromotionTypeEnum.PERCENT) {
          percentPromotionOnBill += e.promotionValue
        }
      })
      return {
        'price': pricePromotionOnBill,
        'percent': percentPromotionOnBill
      }
    },
    /**
     * Promotion code not exist
     */
    promotionCodeNotExist(text) {
      return this.$t(text);
    },
    /**
     * Apply promotion code
     */
    applyPromotionCode: function() {
      if (functionUtils.validateStringIsNull(this.promotionCode)) {
        this.promotionCodeRules = [
          (v) => !!v || this.$t("login.thisFieldIsRequired"),
        ];
      }
      if (this.$refs.entryForm.validate()) {
        let filter = {
          code: this.promotionCode,
          orderCode: this.orderCode
        };
        this.CHECK_PROMOTION_CODE(filter)
          .then(
            function(res) {
              let data = res.data.data
              // this.priceReduced = data.value
              // this.promotionType = data.type
              // this.promotionCodeValid = true
              // this.promotionId = data.id
              // this.promotionCodeOrigin = data.code
              let index = this.promotionDataList.findIndex(x => x.promotionId === data.id)
              if (index === -1) {
                let voucherName = ''
                let voucherPromotionId = null
                let checkPromotionOnVoucher = this.cartList.findIndex(
                  x => data.workpiece.includes(x.id) && 
                  x.isProductPromotion===false &&
                  x.promotionIdApply===null &&
                  x.numberOfUsesVoucherValid !== 0)
                let checkPromotionInOrderCart = this.cartList.findIndex(
                  x => data.workpiece.includes(x.id))
                if (data.apply_type === VoucherProductApplyType.VOUCHER) {
                  // voucher use other promotion
                  if (checkPromotionInOrderCart !== -1) {
                    if (checkPromotionOnVoucher === -1) {
                      this.promotionCodeRules.push(
                        this.promotionCodeNotExist("cart.promotionCodeAlreadyUsed")
                      );
                      this.$refs.entryForm.validate()
                      return
                    }
                    // update promotion list
                    voucherName = this.cartList[checkPromotionOnVoucher].name
                    voucherPromotionId = this.cartList[checkPromotionOnVoucher].id
                    this.cartList[checkPromotionOnVoucher].isProductPromotion = true
                    this.cartList[checkPromotionOnVoucher].promotionIdApply = data.id
                    let item = {
                      promotionCode: data.code,
                      promotionType: data.type,
                      promotionValue: data.value,
                      promotionId: data.id,
                      promotionCodeValid: true,
                      applyType: data.apply_type,
                      voucherPromotionId: voucherPromotionId,
                      voucherName: voucherName
                    }
                    this.promotionDataList.push(item)
                  }
                } else {
                  // check promotion on bill
                  let checkPromotionOnBill = this.promotionDataList.findIndex(x => x.applyType === VoucherProductApplyType.ORDER)
                  if (checkPromotionOnBill !== -1) {
                    this.promotionCodeRules.push(
                      this.promotionCodeNotExist("cart.promotionCodeAlreadyUsed")
                    );
                    this.$refs.entryForm.validate()
                    return
                  }
                  // 
                  let item = {
                    promotionCode: data.code,
                    promotionType: data.type,
                    promotionValue: data.value,
                    promotionId: data.id,
                    promotionCodeValid: true,
                    applyType: data.apply_type,
                    voucherPromotionId: voucherPromotionId,
                    voucherName: voucherName
                  }
                  this.promotionDataList.push(item)
                }
              }
              this.promotionCodeValid = this.promotionDataList.length > 0 ? true : false
              this.promotionCode = null
              // short promotion list
              this.promotionDataList.sort((a, b) => b.applyType - a.applyType)
              this.setPromotionSession()
            }.bind(this)
          )
          .catch(
            function(error) {
              // this.promotionCodeValid = false
              this.promotionCodeValid = this.promotionDataList.length > 0 ? true : false
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText;
                this.promotionCodeRules.push(
                  this.promotionCodeNotExist(errorText)
                );
              } else {
                this.promotionCodeRules.push(
                  this.promotionCodeNotExist("common.somethingWentWrong")
                );
              }
              this.$refs.entryForm.validate()
            }.bind(this)
          );
      }
    },
    /**
     * Increase quantity
     */
    increaseQuantity: function(cart) {
      if (cart.quantity + 1 > cart.warehouse) {
        this.$toast.open({
          message: this.$t("cart.addToCartOverQuantity"),
          type: "error",
          ...this.$toastConfig,
        });
      } else if (cart.numberOfUsesVoucherValid < cart.quantity + 1) {
        this.$toast.open({
          message: this.$t('cart.productCanBuyTimes', {'0': cart.numberOfUsesVoucherValid}),
          type: "error",
          ...this.$toastConfig,
        });
      } else {
        cart.quantity = cart.quantity + 1;
        this.updateVoucherOrderCart(cart.voucherOrderCartItemId, cart.quantity)
      }
    },
    decreaseQuantity: function(cart) {
      if (cart.quantity > 1 && cart.numberOfUsesVoucherValid > 0) {
        cart.quantity = cart.quantity - 1;
        this.updateVoucherOrderCart(cart.voucherOrderCartItemId, cart.quantity)
      }
    },
    /**
     * Buy voucher
     */
    onBuyVoucherDirectly: function() {
      let method = this.paymentMethods.find(element => {
        return element.type == this.paymentMethodSelected
      })
      this.$refs.confirmModal.onShowModal(this.orderCode, this.paymentMethodSelected, method ? method.description : '', this.formatMoney(this.getFinalPrice()));
    },
    onConfirmBuyVoucherDirectly: function(data) {
      if ((this.paymentMethodSelected === PaymentMethodType.CASH_ON_DELIVERY) || (this.paymentMethodSelected === PaymentMethodType.BANK_TRANSFER)) {
        this.processBuyVoucherDirectly()
      } else if (this.paymentMethodSelected === PaymentMethodType.BAO_KIM_WALLET) {
        this.baoKimPaymentMethodSelect = data.baoKimPaymentMethodSelect
        this.processBaoKimPayment()
      } else {
        if (this.paymentMethodSelected === PaymentMethodType.MOMO_WALLET) {
          this.processMomoPayment()
        // } else if (this.paymentMethodSelected === PaymentMethodType.ZALOPAY_WALLET) {
        }
      }
    },
    processBuyVoucherDirectly: function() {
      if (this.cartList.length == 0 || !this.orderCode) {
        this.$toast.open({
          message: this.$t("cart.noProductsInCart"),
          type: "error",
          ...this.$toastConfig,
        });
        return;
      }
      let filter = {
        cartList: this.cartList,
        promotionCode: this.promotionCodeOrigin,
        promotionType: this.promotionType,
        promotionValue: this.priceReduced,
        promotionId: this.promotionId,
        promotionCodeValid: this.promotionCodeValid,
        deliveryInfo: this.SHIPMENT_DETAILS_DATA.id,
        paymentMethod: this.paymentMethodSelected,
        orderCode: this.orderCode,
        promotionList: this.promotionDataList,
        orderTotalPrice: this.getFinalPrice(),
        totalPromotionPrice: this.totalPromotionPrice
      };
      this.isLoading = true
      this.ORDER_VOUCHER_PRODUCT(filter)
        .then(
          function() {
            this.currentStep = PaymentSteps.DELIVERY_INFO
            this.isLoading = false
            this.$toast.open({
              message: this.$t("cart.successfullyOrderedVoucher"),
              type: "success",
              ...this.$toastConfig,
            });
            this.getVoucherOrderCart();
            this.promotionCode = null
            this.promotionCodeValid = false
            this.priceReduced = null
            this.orderCode = null
            this.promotionDataList = []
            this.totalPromotionPrice = 0
            this.removePromotionSession()
          }.bind(this)
        )
        .catch(
          function(error) {
            this.isLoading = false
            this.$refs.confirmModal.onHideModal()
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText;
              this.$toast.open({
                message: this.$t(errorText),
                type: "error",
                ...this.$toastConfig,
              });
            } else {
              this.$toast.open({
                message: this.$t("common.somethingWentWrong"),
                type: "error",
                ...this.$toastConfig,
              });
            }
          }.bind(this)
        );
    },
    processMomoPayment: function () {
      let entityId = sessionStorage.getItem('entityId')
      let filter = {
        // cartList: this.cartList,
        entityId: entityId,
        // promotionCode: this.promotionCodeOrigin,
        // promotionType: this.promotionType,
        // promotionValue: this.priceReduced,
        // promotionId: this.promotionId,
        // promotionCodeValid: this.promotionCodeValid,
        // deliveryInfo: this.SHIPMENT_DETAILS_DATA.id,
        // paymentMethod: this.paymentMethodSelected,
        price: this.getFinalPrice(),
        orderCode: this.orderCode
      };
      this.isLoading = true
      this.REQUEST_MOMO_PAYURL(filter)
        .then(
          function(res) {
            let data = res.data.data
            this.isLoading = false
            this.$refs.paymentMomoModal.onShowModal(data.payUrl, data.qrCodeUrl, data.orderId, data.amount)
            // this.currentStep = PaymentSteps.DELIVERY_INFO
            // this.$toast.open({
            //   message: this.$t("cart.successfullyOrderedVoucher"),
            //   type: "success",
            //   ...this.$toastConfig,
            // });
            // this.REMOVE_ALL_PRODUCT_FROM_CART();
            // this.promotionCode = null
            // this.promotionCodeValid = false
            // this.priceReduced = null
            // this.orderCode = null
          }.bind(this)
        )
        .catch(
          function(error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText;
              this.$toast.open({
                message: this.$t(errorText),
                type: "error",
                ...this.$toastConfig,
              });
            } else {
              this.$toast.open({
                message: 'Some thing wrong with momo payment',
                type: "error",
                ...this.$toastConfig,
              });
            }
          }.bind(this)
        );
    },
    formatMoney: function(price) {
      return functionUtils.convertFormatNumberToMoney(price.toString());
    },
    /**
     * Get total price
     */
    getTotalPrice: function() {
      let price = 0;
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        price += this.cartList[i].price * this.cartList[i].quantity;
      }
      return functionUtils.convertFormatNumberToMoney(price.toString());
    },
    /**
     * Get total price with fee
     */
    getTotalPriceWithFee: function() {
      let price = 0;
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        price += this.cartList[i].price * this.cartList[i].quantity;
      }
      return functionUtils.convertFormatNumberToMoney(
        (price + (price * this.tax) / 100).toString()
      );
    },
    /**
     * Remove product from cart
     */
    onRemoveProductFromCart: function(productName, id, workpieceId) {
      // remove promotion 
      let index = this.promotionDataList.findIndex(x => x.voucherPromotionId === workpieceId)
      if (index !== -1) {
        this.onRemovePromotion(this.promotionDataList[index], this.promotionDataList)
      }
      // remove product in cart
      this.DELETE_VOUCHER_ORDER_CART({ id: id }).then(
        function() {
          this.getVoucherOrderCart()
          this.$toast.open({
            message: `${this.$t(
              "cart.successfullyDeletedVoucher"
            )} '${productName}' ${this.$t("cart.outOfCart")}`,
            type: "error",
            ...this.$toastConfig,
          });
        }.bind(this)
      );
    },
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    getVoucherOrderCart: function () {
      let filter = {
        params: {
          storeChannelId: StoreChannelType.PRODUCT_PORTAL
        }
      }
      this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    updateVoucherOrderCart: function (id, quantity) {
      let data = {
        id: id,
        quantity: quantity
      }
      this.UPDATE_VOUCHER_ORDER_CART(data).then(
        function () {
          this.getVoucherOrderCart()
        }.bind(this)
      )
    },
    getBaoKimPaymentList: function () {
      this.GET_BAO_KIM_PAYMENT_LIST()
    },
    processBaoKimPayment: function () {
      if (this.cartList.length === 0 || !this.orderCode) {
        this.$toast.open({
          message: this.$t("cart.noProductsInCart"),
          type: "success",
          ...this.$toastConfig,
        });
        return
      }
      let filter = {
        cartList: this.cartList,
        promotionCode: this.promotionCodeOrigin,
        promotionType: this.promotionType,
        promotionValue: this.priceReduced,
        promotionId: this.promotionId,
        promotionCodeValid: this.promotionCodeValid,
        deliveryInfo: this.SHIPMENT_DETAILS_DATA.id,
        paymentMethod: this.paymentMethodSelected,
        orderCode: this.orderCode,
        baoKimPaymentMethodSelect: this.baoKimPaymentMethodSelect,
        promotionList: this.promotionDataList
      }
      this.isLoading = true
      this.ADD_BAO_KIM_PAYMENT(filter)
        .then(
          function (res) {
            this.isLoading = false
            this.currentStep = PaymentSteps.DELIVERY_INFO
            this.getVoucherOrderCart()
            this.$toast.open({
              message: this.$t("cart.successfullyOrderedVoucher"),
              type: "success",
              ...this.$toastConfig,
            });
            this.promotionCode = null
            this.promotionCodeValid = false
            this.priceReduced = null
            this.orderCode = null
            this.promotionDataList = []
            this.totalPromotionPrice = 0
            this.removePromotionSession()
            let data = res.data
            let paymentUrl = data.payment_url
            window.open(paymentUrl, '_self')
          }.bind(this)
        )
        .catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.$toast.open({
                message: `${this.$t(errorText)}`,
                type: "error",
                ...this.$toastConfig,
              });
            } else if (!functionUtils.isNull(error.response.data.message)) {
              let message = error.response.data.message;
              this.$toast.open({
                message: message,
                type: "error",
                ...this.$toastConfig,
              });
            } else {
              this.$toast.open({
                message: `${this.$t('common.somethingWentWrong')}`,
                type: "error",
                ...this.$toastConfig,
              });
            }
          }.bind(this)
        )
    },
    onRemovePromotion: function(data, i) {
      this.promotionDataList.splice(i, 1)
      if (data.applyType === VoucherProductApplyType.VOUCHER && data.voucherPromotionId !== null) {
        let index = this.cartList.findIndex(x => x.id === data.voucherPromotionId)
        if (index !== -1) {
          this.cartList[index].isProductPromotion = false
          this.cartList[index].promotionIdApply = null
        }
      }
      this.promotionCodeValid = this.promotionDataList.length > 0 ? true : false
      this.setPromotionSession()
    },
    setPromotionSession: function () {
      sessionStorage.setItem('promotionSession', JSON.stringify(this.promotionDataList))
    },
    getPromotionSession: function () {
      let promotionSessionData = sessionStorage.getItem('promotionSession')
      if (promotionSessionData) {
        this.promotionDataList = JSON.parse(promotionSessionData)
      }
      this.promotionDataList.forEach(e => {
        let checkPromotionOnVoucher = this.cartList.findIndex(x => e.voucherPromotionId===x.id && x.isProductPromotion===false)
        if (e.applyType === VoucherProductApplyType.VOUCHER) {
          // update voucher is promotion
          if (checkPromotionOnVoucher !== -1) {
            this.cartList[checkPromotionOnVoucher].isProductPromotion = true
            this.cartList[checkPromotionOnVoucher].promotionIdApply = e.promotionId
          }
        }
      })
      this.promotionCodeValid = this.promotionDataList.length > 0 ? true : false
    },
    removePromotionSession: function () {
      sessionStorage.removeItem('promotionSession')
    },
    ...mapActions([
      "DELETE_VOUCHER_ORDER_CART",
      "REMOVE_ALL_PRODUCT_FROM_CART",
      'UPDATE_VOUCHER_ORDER_CART',
      "ORDER_VOUCHER_PRODUCT",
      "CHECK_PROMOTION_CODE",
      "GET_PAYMENT_METHODS_LIST",
      "GET_CREATE_ORDER_CODE_PREPARE",
      'REQUEST_MOMO_PAYURL',
      'GET_VOUCHER_ORDER_CART_LIST',
      'GET_BAO_KIM_PAYMENT_LIST',
      'ADD_BAO_KIM_PAYMENT'
    ]),
  },
};
</script>

<style lang="scss" scoped>
#about {
  background-color: #f4f7f5;
}
.cursor-pointer {
  cursor: pointer;
}
.vh-150px {
  height: 150px;
}
table {
  border-collapse: collapse;
}

tr, td {
  border-bottom: 1px solid #ccc;
}
td {
  padding-top: 5px;
}
input[type=radio] {
  margin-top: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--horizontal) .v-stepper__label {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  }
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--horizontal) .v-stepper__step__step {
      margin-right: 12px;
    }
  }
}
input {
  color: #2c3e50;
  font-weight: bold;
}
.paymentLogo {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  vertical-align: middle;
}
label:hover {
  text-decoration: underline;
}

.v-data-table ::v-deep.v-data-table__wrapper table tbody tr:hover {
  background-color: transparent !important;
}

.img-voucher-class {
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  min-width: 70px;
  min-height: 50px;
}
</style>
