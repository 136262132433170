<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        persistent
        transition="dialog-top-transition"
        max-width="600"
        v-model="isShowModal">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>
              <img v-if="paymentMethod==PaymentMethodType.MOMO_WALLET" src="@/assets/logo_momo.png" class="paymentLogo">
              <img v-else-if="paymentMethod==PaymentMethodType.ZALOPAY_WALLET" src="@/assets/logo_zalopay.png" class="paymentLogo">
              {{ $t(getPaymentMethodName(paymentMethod)) }}
            </v-toolbar>
            <v-card-text>
              <div class="my-3">
                <div class="text-left font-large pa-2">{{ $t('productOrder.voucherRecipientInfo') }}</div>
                <div class="ml-5 d-flex">
                  <span>{{ $t('common.fullname') + ': ' }} <strong>{{ (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.fullname ? SHIPMENT_DETAILS_DATA.fullname : '')}}</strong></span>
                </div>
                <div class="ml-5 d-flex">
                  <span>{{ $t('profile.phone') + ': ' }}<strong>{{ (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.phone ? SHIPMENT_DETAILS_DATA.phone : '') }}</strong></span>
                </div>
                <div class="ml-5 d-flex">
                  <span>{{ $t('common.email') + ': ' }}<strong>{{ (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.email ? SHIPMENT_DETAILS_DATA.email : '') }}</strong></span>
                </div>
                <div class="font-small text-left ml-5 pt-1 pl-3">
                  <span class="red--text">{{ $t('cart.voucherInfoSentViaEmail') }}. {{ $t('cart.makeSureEmailIsEnteredCorrectly') }}</span>
                </div>
              </div>
              <v-divider />
              <div class="text-left font-large mt-1 pa-2">
                <span>{{ $t('cart.paymentAmount') + ': ' }}
                  <span
                    id="paymentAmount"
                    style="font-weight: bold; color: #ff6d6d;">{{ paymentAmount }} VND</span>
                </span>
              </div>
              <div v-if="description" class="text-left font-large pa-2 my-3" style="white-space: pre-wrap;">{{ description }}</div>
              <div v-if="paymentMethod==PaymentMethodType.BANK_TRANSFER">
                <div v-if="isConfirmedPayment" class="text-left px-3">
                  <span>{{ $t('paymentMethod.transferContent') }}: <input type="text" disabled :value="contentTransfer" id="transferContent" :style="`width: ${ contentTransfer.length + 1 }ch;`">
                    <div class="tooltip">
                      <v-icon class="cursor: pointer;" @onmouseout="outFunc" @click="copyTextToClipboard('transferContent')">mdi-content-copy</v-icon>
                      <span class="tooltiptext" name="copyTooltip">{{ $t('common.copyToClipboard') }}</span>
                    </div>
                  </span>
                  <div v-for="(bankInfo, index) in bankInfoList" :key="bankInfo + index">
                    <span class="d-block font-weight-bold mt-2">{{ index + 1 }}. {{ bankInfo.name_bank }}</span>
                    <div class="ml-4 d-flex">
                      <span>{{ $t('paymentMethod.accountNumber') }}: <input type="text" disabled :value="bankInfo.number_account" :id="bankInfo.number_account" :style="`width: ${ bankInfo.number_account ? bankInfo.number_account.length + 1 : 1 }ch;`">
                      <div class="tooltip">
                        <v-icon class="cursor: pointer;" @onmouseout="outFunc" @click="copyTextToClipboard(bankInfo.number_account)">mdi-content-copy</v-icon>
                        <span class="tooltiptext" name="copyTooltip">{{ $t('common.copyToClipboard') }}</span>
                      </div>
                      </span>
                    </div>
                    <div class="ml-4 d-flex">
                      <span>{{ $t('paymentMethod.accountHolder') }}: <strong>{{ bankInfo.name_account }}</strong></span>
                    </div>
                    <div class="ml-4 d-flex">
                      <span>{{ $t('paymentMethod.branchBank') }}: <strong>{{ bankInfo.branch_bank }}</strong></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="paymentMethod==PaymentMethodType.BAO_KIM_WALLET">
                <div class="text-left font-large pa-2">{{ $t('baoKimConfig.baoKimPaymentMethodType') }}</div>
                <div
                  v-for="(paymentMethod, index) in baoKimPaymentMethods"
                  :key="paymentMethod + index"
                  class="d-flex"
                >
                  <div
                    class="ml-4 form-check"
                  >
                    <input
                      :id="`${paymentMethod.text}${paymentMethod.value}`"
                      :value="paymentMethod.value"
                      v-model="baoKimPaymentMethodTypeSelected"
                      class="form-check-input"
                      type="radio"
                      name="baoKimPaymentMethods"
                      @change="getBaoKimPaymentInPaymentMethod(baoKimPaymentMethodTypeSelected)"
                    >
                    <label
                      :for="`${paymentMethod.text}${paymentMethod.value}`"
                      class="form-check-label ml-2">
                      {{ $t(paymentMethod.text) }}
                    </label>
                  </div>
                </div>
              </div>
              <v-divider
                v-if="baoKimPaymentMethodTypeSelected !== null"
                class="my-2"/>
              <div v-if="paymentMethod==PaymentMethodType.BAO_KIM_WALLET && baoKimPaymentMethodTypeSelected !== null">
                <div class="text-left font-large pa-2">{{ $t('baoKimConfig.baoKimPaymentMethod') }}</div>
                <div style="overflow: auto; max-height: 250px;">
                  <div
                    v-for="(paymentMethod, index) in baoKimPaymentInPaymentMethodList"
                    :key="paymentMethod + index"
                    class="d-flex"
                  >
                    <!-- {{ paymentMethod }} -->
                    <div
                      class="ml-4 form-check"
                    >
                      <input
                        :id="`${paymentMethod.id}${paymentMethod.bank_short_name}`"
                        :value="paymentMethod.id"
                        v-model="baoKimPaymentMethodSelect"
                        class="form-check-input"
                        type="radio"
                        name="baoKimPaymentMethodSelect"
                      >
                      <img
                        :src="paymentMethod.bank_logo ? paymentMethod.bank_logo : noImg"
                        style="border: 2px solid #fff; max-width: 50px; max-height: 50px">
                      <label
                        :for="`${paymentMethod.id}${paymentMethod.bank_short_name}`"
                        class="form-check-label ml-2">
                        {{ paymentMethod.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
              <v-btn v-if="!isConfirmedPayment" color="error" @click="dialog.value = false">{{ $t('cart.cancelPayment') }}</v-btn>
              <v-btn v-if="!isConfirmedPayment" :loading="isLoading" color="primary" @click="onConfirm">{{ $t('cart.confirmPayment') }}</v-btn>
              <v-btn v-if="isConfirmedPayment" color="warning" @click="dialog.value = false">{{ $t('confirmModal.btn.close') }}</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import functionUtils from "Utils/functionUtils";
import stringUtils from "Utils/stringUtils";
import PaymentMethodType from "Enum/paymentMethodType"
export default {
  props: {
  },
  data: () => ({
    isShowModal: false,
    bankInfoList: [],
    description: null,
    orderCode: null,
    contentTransfer: '',
    PaymentMethodType: PaymentMethodType,
    paymentMethod: PaymentMethodType.CASH_ON_DELIVERY,
    baoKimPaymentMethods: [],
    baoKimPaymentMethodTypeSelected: null,
    baoKimPaymentInPaymentMethodList: [],
    baoKimPaymentMethodSelect: null,
    isLoading: false,
    isConfirmedPayment: false,
    paymentAmount: null
  }),
  created() {
    this.GET_BANK_TRANSFER_LIST().then(
      function (res) {
        let data = res.data
        this.bankInfoList = []
        for (let i = 0, size = data.length; i < size; i++) {
          this.bankInfoList.push(data[i])
        }
      }.bind(this)
    )
    let paymentMethodList = this.GET_BAO_KIM_PAYMENT_LIST_DATA && this.GET_BAO_KIM_PAYMENT_LIST_DATA.data
    this.getBaoKimPaymentMethodList(paymentMethodList)
  },
  computed: {
    ...mapGetters(['CURRENT_ACCOUNT_DATA', 'SHIPMENT_DETAILS_DATA', 'GET_BAO_KIM_PAYMENT_LIST_DATA']),
  },
  watch: {
    GET_BAO_KIM_PAYMENT_LIST_DATA: function () {
      let paymentMethodList = this.GET_BAO_KIM_PAYMENT_LIST_DATA && this.GET_BAO_KIM_PAYMENT_LIST_DATA.data
      this.getBaoKimPaymentMethodList(paymentMethodList)
    }
  },
  methods: {
    generateTransferContent: function () {
      let fullname = this.CURRENT_ACCOUNT_DATA ? functionUtils.concatFullname(this.CURRENT_ACCOUNT_DATA.last_name, this.CURRENT_ACCOUNT_DATA.first_name) : ''
      return this.orderCode + '_' + functionUtils.removeVietnameseTones(fullname)
    },
    setTootltipElementsHint: function (text) {
      var tooltips = document.getElementsByName("copyTooltip");
      var i;
      for (i = 0; i < tooltips.length; i++) {
        tooltips[i].innerHTML = text;
      }
    },
    outFunc() {
      // var tooltip = document.getElementById("myTooltip");
      // tooltip.innerHTML = this.$t("common.copyToClipboard");
      this.setTootltipElementsHint(this.$t("common.copyToClipboard"))
    },
    /**
    * Copy text to clipboard
    */
    copyTextToClipboard: function (elementId) {
      // Todo
      /* Get the text field */
      var copyText = document.getElementById(elementId);
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);
      this.setTootltipElementsHint(this.$t("common.copied") + ': ' + copyText.value)
    },
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    onConfirm: function() {
      if (this.paymentMethod === PaymentMethodType.BAO_KIM_WALLET) {
        if (functionUtils.validateStringIsNull(this.baoKimPaymentMethodTypeSelected)) {
          this.$toast.open({
            message: `${this.$t(
              "baoKimConfig.baoKimPaymentMethodType"
            )}`,
            type: "error",
            ...this.$toastConfig,
          });
        } else if (functionUtils.validateStringIsNull(this.baoKimPaymentMethodSelect)) {
          this.$toast.open({
            message: `${this.$t(
              "baoKimConfig.baoKimPaymentMethod"
            )}`,
            type: "error",
            ...this.$toastConfig,
          });
        } else {
          this.isLoading = true
          this.$emit('confirm', {
            baoKimPaymentMethodTypeSelected: this.baoKimPaymentMethodTypeSelected,
            baoKimPaymentMethodSelect: this.baoKimPaymentMethodSelect
          })
        }
      } else {
        if (this.paymentMethod !== PaymentMethodType.BANK_TRANSFER) {
          this.isShowModal = false;
        } else {
          this.isConfirmedPayment = true;
        }
        this.$emit("confirm");
      }
    },
    onHideModal: function() {
      this.isShowModal = false
    },
    onShowModal: function(orderCode, paymentMethod, description, paymentAmount) {
      this.orderCode = orderCode
      this.description = description
      this.paymentMethod = paymentMethod
      if (paymentMethod==PaymentMethodType.BANK_TRANSFER) {
        this.contentTransfer = this.generateTransferContent()
      }
      this.paymentAmount = paymentAmount
      this.isLoading = false
      this.isShowModal = true
      this.isConfirmedPayment = false
    },
    getBaoKimPaymentMethodList: function (dataList) {
      this.baoKimPaymentMethods = []
      if (dataList !== undefined) {
        let baoKimPaymentMethodList = stringUtils.BaoKimPaymentMethodList
        baoKimPaymentMethodList.forEach(e => {
          let index = dataList.findIndex(x => x.type === e.value)
          if (index !== -1) {
            this.baoKimPaymentMethods.push(e)
          }
        })
      }
    },
    getBaoKimPaymentInPaymentMethod: function (paymentMethodSelect) {
      this.baoKimPaymentInPaymentMethodList = []
      let paymentMethodList = this.GET_BAO_KIM_PAYMENT_LIST_DATA && this.GET_BAO_KIM_PAYMENT_LIST_DATA.data
      if (paymentMethodList !== undefined) {
        let resultList = paymentMethodList.filter(x => x.type === paymentMethodSelect)
        this.baoKimPaymentInPaymentMethodList = resultList
      }
    },
    ...mapActions([
      'GET_BANK_TRANSFER_LIST'
    ]),
  },
};
</script>

<style lang="scss" scoped>
input {
  color: #2c3e50;
  font-weight: bold;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.paymentLogo {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  vertical-align: middle;
}
</style>
